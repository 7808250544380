<script>
import StandForm from "@/views/menu/stand/standForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    standService: "standService",
    visitorLevelService: "visitorLevelService"
  },
})
export default class StandDetail extends mixins(StandForm) {
  formId = "detail-stand-form";

  get actions() {
    return [
      {
        id: "clone",
        type: "submit",
        text: this.translations.labels.button_clone,
        handle: this.clone,
        leftIcon: "mdi-content-duplicate",
      },

    ];
  }

  get decoratedElements() {
    return [
      ...[
        {
          id: "entityLink",
          label: this.translations.labels.common_form_entity_link,
          type: "text",
          readonly: true,
          copyToClipboard: true,
        },
        {
          id: "agendaLink",
          label: this.translations.labels.common_form_agenda_link,
          type: "text",
          readonly: true,
          copyToClipboard: true,
        }
      ],
      ...this.allReadonlyFields
    ];
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle() {
    return this.translations.pageTitles.stands_detail;
  }

  async afterCreate() {
    this.model = await this.standService.read(this.$route.params.id);
    this.model.entityLink = "Not yet generated";

    this.setVisitorLevels(await this.visitorLevelService.enabledLevels(this.model.id));
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    this.isReady = true;

    this.model.entityLink = Utility.generateEntityLink(this?.event, "Stand", this.model.id);
    this.model.agendaLink = Utility.generateEntityLink(this?.event, "agenda", null, { parent: this.model.id });
  }

  clone() {
    this.$router.push(`/stand/${this.model.id}/clone`);
  }
}
</script>
